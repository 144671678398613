
import styled, { css } from 'styled-components';
const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';

const CircleContainer = styled.div`
  @media (max-width: 660px){
    margin-right: 0px;
  }
  .flex {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .quiz{
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0px;
    color: rgb(156,156,156);
    font-family: 'Rubik-Regular';
    text-align: end;
  }
`;

const NewContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 15px;
  float: left;
  width: 100%;
  height: 75px;
  .typeOfWork {
    font-size: 13px;
    letter-spacing: 0px;
    color: rgb(21,159,202);
    font-family: 'Rubik-Regular';
    margin-bottom: 5px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .workName {
    font-size: 18px;
    line height:24px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    font-family: 'Rubik-Regular';
    text-align: left;
    overflow: hidden;
    cursor: pointer;
    align-items: center;
    margin: auto;
    margin-left: 0px;
  }
  >div:first-child{
   padding-bottom: 5px;
   color: #0D4270;
  }
  >div:last-child{
  }
  `;

const NewChampionsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${({ width }) => width?width:"calc((100% - 50px) / 3)"};
  border-radius: 6px;
  margin-right: ${({ margin }) => margin?margin:""};
  @media(max-width: 600px) {
    width: 100%;
  }
  > div {
    padding: 5px;
    height: 94%;
  
  }

  .flex-item:not(:nth-child(3n)) {
  margin-right: 0;
}
&:nth-child(3n){
    margin-right: 0;
  }

  .recommended-survey{
    .flex-item:not(:nth-child(3n)) {
  margin-right: 0;
}
  }
`;

const NewCard = styled.div`
  background-color: #ffffff;
  float: left;
  border-radius: 6px;
  margin-bottom: 25px;
  padding: 0px!important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  img {
    width: 100%;
    height: 250px;
    float: left;
    cursor:pointer;
  }

  .educateImageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 250px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
    .getStarted {
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 18px;
      line-height: 92%;
      letter-spacing: 0px;
      width: 170px;
      height: 40px;
      background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%);
      color: white;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align:center;
    }
  }
  .content {
    display: ${({ recommended }) => recommended ? 'flex' : 'block'};
    align-items: center;
    padding: 10px 15px;
    float: left;
    width: 100%;
    height: calc(100% - 235px);
    .typeOfWork {
      font-size: 13px;
      letter-spacing: 0px;
      color: rgb(21,159,202);
      font-family: ${RegularFont};
      margin-bottom: 5px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .workName {
      font-size: 18px;
      letter-spacing: 0px;
      color: rgb(51,51,51);
      font-family: ${RegularFont};
      margin-bottom: ${({ recommended }) => recommended ? '0' : '5px'};
      text-align: left;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-top: 5px;
    }
    .description {
      font-size: 13px;
      letter-spacing: 0px;
      line-height: 18px;
      color: #999999;
      font-family: ${RegularFont};
      text-align: left;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }

  .video-category {
    width: 100%;
    height: 235px;

    img {
      width: auto;
      height: 100px;
    }
  }
`;

const NewVideoPlayer = styled.div`
  width: 100%;
  float: left;
  height: ${({ height }) => height ? height : '250px'};
  text-align: center;
  cursor: pointer;
  position: relative;

  > div {
    width: 100% !important;
    height: 100% !important;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: ${({ radius }) => radius && '6px'};
    @media (max-width: 1200px) {
      width: auto;
    }
  }

  ${({ wellnessDashboard }) => wellnessDashboard && css`
    img {
      max-width: 400px;
    }
  `}
  @media (max-width: 500px){
    height: 200px;
  }
  .title{
    font-size: 18px;
    text-transform: Capitalize;
    width: 100%;
    float: left;
    text-align: left;
    position: absolute;
    font-family: ${MediumFont};
    top: 25px;
    left: 35px;
    color: #FFFFFF;
    width: auto !important;
    height: unset !important;
  }
  .bg-opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.329545);
    border-radius: 6px;
  } 
`;

const NewPlayIcon = styled.p`
  width: 66px;
  height: 66px;
  background-color: #000000;
  border-radius: 50%;
  vertical-align: middle;
  position: absolute;
  left: calc(50% - 33px);
  top: calc(50% - 33px);
  opacity: ${({ opacity }) => opacity ? '1' : '0.5'};

  > i {
    color: white;
    font-size: 20px;
    margin-left: 3px;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;
const NewCircle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 16px;
  border: ${({ checked }) => checked ? '3px solid #96C26E' : '1px solid #9c9c9c'};
  border-color: ${({ checked }) => checked ? '#96C26E' : '#9c9c9c'};
  margin-right: ${({ newMargin }) => newMargin ? '7px' : 'none'};

  margin-right: 10px;
  margin-bottom: 5px;
`;

const NewCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ marginT }) => marginT ? marginT : 'none'};
`;

const NewTitle = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  color: #005c87;
  display: inline-block;
  padding: 0px};
  text-transform: capitalize;
  margin-bottom: ${({ margin }) => margin ? '24px' : '15px'};
  font-family: Rubik-Medium;
  margin-top: ${({ marginT }) => marginT ? marginT : 'none'};
`;

const Tick = styled.div`
  content: "";
  position: relative;
  display: block;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: ${({ checked }) => checked ? '#96C26E' : 'white'};
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top-color: transparent;
  border-left-color: transparent;
`;

const VideoPlayer = styled.div`
  width: 100%;
  float: left;
  height: ${({ height }) => height ? height : '250px'};
  text-align: center;
  cursor: pointer;
  position: relative;
  
  > div {
    width: 100% !important;
    height: 100% !important;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: ${({ radius }) => radius && '6px'};
    @media (max-width: 1200px) {
      width: auto;
    }
  }

  ${({ wellnessDashboard }) => wellnessDashboard && css`
    img {
      max-width: 400px;
    }
  `}
  @media (max-width: 500px){
    height: 200px;
  }
  .title{
    font-size: 18px;
    text-transform: Capitalize;
    width: 100%;
    float: left;
    text-align: left;
    position: absolute;
    font-family: ${MediumFont};
    top: 25px;
    left: 35px;
    color: #FFFFFF;
    width: auto !important;
    height: unset !important;
  }
  .bg-opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.329545);
    border-radius: 6px;
  }
  .getStarted{
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 122px;
    height: 37px;
    background-color: #FD7175;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    color: white;
  }
`;

const EventCard = styled.div`
  background-color: #ffffff;
  float: left;
  border-radius: 6px;
  margin-bottom: 25px;
  padding: 0px!important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  // box-shadow: 0px 8px 25px 0px #0D427040;
  img {
    width: 100%;
    height: 250px;
    float: left;
    cursor:pointer;
  }

  .educateImageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 250px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
    .getStarted {
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 18px;
      line-height: 92%;
      letter-spacing: 0px;
      min-width: 368px;
      height: 48px;
      background: linear-gradient(227.68deg, #00F0FF 15.32%, #3586FF 85.04%);
      color: white;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align:center;
      padding:0px 15px;
      >img{
        width:24px;
        height:24px;
        margin-right:12px;
      }
    }
  }
`;

const NewContentV2 = styled.div`
  display: flex;
  width: 100%;
  min-height: 75px;
  padding:15px;
  .firstContainer{
    display:block;
    width:80%;
    .name{
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 20px;
      color:#005C87;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .categoryName{
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color:#005C87;
      margin-top:5px;
      text-transform: capitalize;
    }
  }
  .imageContainer{

  }
  `;

const CircleContainerV2 = styled.div`
  display:block;
  margin: auto;
  margin-right: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  .quiz{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color:#005c87;
  }
  .icon{
    width:24px;
    height:24px;
    margin-bottom:5px;
  }
`;

const CircleV2 = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 16px;
  border: ${({ checked }) => checked ? '3px solid #96C26E' : '1px solid #005C87'};
  border-color: ${({ checked }) => checked ? '#669db7' : '#669db7'};
  margin: auto;
  margin-bottom: 5px;
`;

const TickV2 = styled.div`
  content: "";
  position: relative;
  display: block;
  left:  ${({ checkedValue }) => checkedValue ? '7px' : '8px'};
  top: 3px;
  top: ${({ checkedValue }) => checkedValue ? '2px' : '3px'};
  width: 6px;
  height: 12px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: ${({ checked }) => checked ? '#96C26E' : 'white'};
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top-color: transparent;
  border-left-color: transparent;
`;

const RecommendedSurveyCard = styled.div`
width: "100%"; 
flexWrap: "wrap";
 ${NewChampionsCard} {
    margin-right: 25px; /* Add margin-right to all items */
  }

   ${NewChampionsCard}:nth-child(3n) {
    margin-right: 0; /* Remove margin-right from every 3rd item */
  }
`


export {RecommendedSurveyCard, CircleContainer, NewContent, NewChampionsCard, NewCard, NewVideoPlayer, NewPlayIcon, NewCircle, NewCardContainer, NewTitle, Tick, VideoPlayer, EventCard, NewContentV2, TickV2, CircleV2, CircleContainerV2 };